<template>
  <div class="">
    <KabMerauke v-if="kabupaten == 'KABUPATEN MERAUKE'" />
    <KabJayapura v-else-if="kabupaten == 'KABUPATEN JAYAPURA'" />
    <KabJayawijaya v-else-if="kabupaten == 'KABUPATEN JAYAWIJAYA'" />
    <KabNabire v-else-if="kabupaten == 'KABUPATEN NABIRE'" />
    <KabKepulauanYapen v-else-if="kabupaten == 'KABUPATEN KEPULAUAN YAPEN'" />
    <KabBiakNumfor v-else-if="kabupaten == 'KABUPATEN BIAK NUMFOR'" />
    <KabPuncakJaya v-else-if="kabupaten == 'KABUPATEN PUNCAK JAYA'" />
    <KabPaniai v-else-if="kabupaten == 'KABUPATEN PANIAI'" />
    <KabMimika v-else-if="kabupaten == 'KABUPATEN MIMIKA'" />
    <KabSarmi v-else-if="kabupaten == 'KABUPATEN SARMI'" />
    <KabYahukimo v-else-if="kabupaten == 'KABUPATEN YAHUKIMO'" />
    <KabTolikara v-else-if="kabupaten == 'KABUPATEN TOLIKARA'" />
    <KabMappi v-else-if="kabupaten == 'KABUPATEN MAPPI'" />
    <KabLannyJaya v-else-if="kabupaten == 'KABUPATEN LANNY JAYA'" />
    <KotaJayapura v-else-if="kabupaten == 'KOTA JAYAPURA'" />
    <KabKeerom v-else-if="kabupaten == 'KABUPATEN KEEROM'" />
    <KabPegununganBintang
      v-else-if="kabupaten == 'KABUPATEN PEGUNUNGAN BINTANG'"
    />
    <KabWaropen v-else-if="kabupaten == 'KABUPATEN WAROPEN'" />
    <KabBovenDigoel v-else-if="kabupaten == 'KABUPATEN BOVEN DIGOEL'" />
    <KabAsmat v-else-if="kabupaten == 'KABUPATEN ASMAT'" />
    <KabSupiori v-else-if="kabupaten == 'KABUPATEN SUPIORI'" />
    <KabMamberamoRaya v-else-if="kabupaten == 'KABUPATEN MAMBERAMO RAYA'" />
    <KabMamberamoTengah v-else-if="kabupaten == 'KABUPATEN MAMBERAMO TENGAH'" />
    <KabYalimo v-else-if="kabupaten == 'KABUPATEN YALIMO'" />
    <KabNduga v-else-if="kabupaten == 'KABUPATEN NDUGA'" />
    <KabPuncak v-else-if="kabupaten == 'KABUPATEN PUNCAK'" />
    <KabDogiyai v-else-if="kabupaten == 'KABUPATEN DOGIYAI'" />
    <KabIntanJaya v-else-if="kabupaten == 'KABUPATEN INTAN JAYA'" />
    <KabDeiyai v-else-if="kabupaten == 'KABUPATEN DEIYAI'" />
  </div>
</template>
<script>
import KabMerauke from "./KabMerauke";
import KabJayawijaya from "./KabJayawijaya";
import KabJayapura from "./KabJayapura";
import KabNabire from "./KabNabire";
import KabKepulauanYapen from "./KabKepulauanYapen";
import KabBiakNumfor from "./KabBiakNumfor";
import KabPuncakJaya from "./KabPuncakJaya";
import KabPaniai from "./KabPaniai";
import KabMimika from "./KabMimika";
import KabSarmi from "./KabSarmi";
import KabYahukimo from "./KabYahukimo";
import KabTolikara from "./KabTolikara";
import KabMappi from "./KabMappi";
import KabLannyJaya from "./KabLannyJaya";
import KotaJayapura from "./KotaJayapura";
import KabKeerom from "./KabKeerom";
import KabPegununganBintang from "./KabPegununganBintang";
import KabWaropen from "./KabWaropen";
import KabBovenDigoel from "./KabBovenDigoel";
import KabAsmat from "./KabAsmat";
import KabSupiori from "./KabSupiori";
import KabMamberamoRaya from "./KabMamberamoRaya";
import KabMamberamoTengah from "./KabMamberamoTengah";
import KabYalimo from "./KabYalimo";
import KabNduga from "./KabNduga";
import KabPuncak from "./KabPuncak";
import KabDogiyai from "./KabDogiyai";
import KabIntanJaya from "./KabIntanJaya";
import KabDeiyai from "./KabDeiyai";

export default {
  name: "maps",
  components: {
    KabJayapura,
    KabJayawijaya,
    KabMerauke,
    KabNabire,
    KabKepulauanYapen,
    KabBiakNumfor,
    KabPuncakJaya,
    KabPaniai,
    KabMimika,
    KabSarmi,
    KabYahukimo,
    KabTolikara,
    KabMappi,
    KabLannyJaya,
    KotaJayapura,
    KabKeerom,
    KabPegununganBintang,
    KabWaropen,
    KabBovenDigoel,
    KabAsmat,
    KabSupiori,
    KabMamberamoRaya,
    KabMamberamoTengah,
    KabYalimo,
    KabNduga,
    KabPuncak,
    KabDogiyai,
    KabIntanJaya,
    KabDeiyai,
  },
  data() {
    return {};
  },
  props: {
    kabupaten: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>
