import { render, staticRenderFns } from "./KabPaniai.vue?vue&type=template&id=6ddf3ad0"
import script from "./KabPaniai.vue?vue&type=script&lang=js"
export * from "./KabPaniai.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports