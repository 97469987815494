<template>
  <div>
    <highcharts ref="barChart" :options="chartOptions"></highcharts>
  </div>
</template>
<script>
export default {
  name: "chart-jenis-lantai",
  props: {
    Triger: null,
    filter_tahun: null,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "Total Keluarga",
            "Total Keluarga Memiliki Rumah",
            "Lahan Milik Sendiri",
            "Lahan Milik Orang Lain",
            "Rumah Dinas",
            "Lainya",
          ],
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal
            );
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: "bold",
              color: "gray",
            },
          },
        },
        legend: {
          enabled: true,
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: [
          {
            name: "Bukan OAP",
            data: [16, 80, 10, 16, 30, 10, 16, 60, 10, 16, 60],
            color: "#07980C",
          },
          {
            name: "OAP",
            data: [36, 120, 20, 36, 120, 20, 36, 120, 20, 36, 120],
            color: "#32C537",
          },
        ],
      },
    };
  },
  watch: {
    filter_tahun(newVal) {
      this.changeChartData(newVal);
    },
  },
  mounted() {
    this.getChartData();
  },
  methods: {
    getChartData() {
      this.$axios
        .get("/v1/sensus/city/jenis-lantai-terluas/" + this.Triger)
        .then((response) => {
          let result = response.data;
          this.chartOptions = {
            chart: {
              type: "bar",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: result.xAxis.categories,
            },
            tooltip: {
              formatter: function () {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "<br/>" +
                  "Total: " +
                  this.point.stackTotal
                );
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "",
              },
              stackLabels: {
                enabled: true,
                style: {
                  fontWeight: "bold",
                  color: "gray",
                },
              },
            },
            legend: {
              enabled: true,
              reversed: true,
            },
            plotOptions: {
              series: {
                stacking: "normal",
              },
            },
            series: result.series,
          };
        });
    },
    changeChartData(filter_tahun) {
      this.$axios
        .post("/city/data-jenis-lantai-terluas", null, {
          params: {
            filter_tahun: filter_tahun,
            id_kabupaten: this.Triger,
          },
        })
        .then((response) => {
          let result = response.data;
          this.chartOptions = {
            chart: {
              type: "bar",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: result.xAxis.categories,
            },
            tooltip: {
              formatter: function () {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "<br/>" +
                  "Total: " +
                  this.point.stackTotal
                );
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "",
              },
              stackLabels: {
                enabled: true,
                style: {
                  fontWeight: "bold",
                  color: "gray",
                },
              },
            },
            legend: {
              enabled: true,
              reversed: true,
            },
            plotOptions: {
              series: {
                stacking: "normal",
              },
            },
            series: result.series,
          };
        });
    },
  },
};
</script>
